import Link from 'next/link'
import { NextSeo } from 'next-seo'
import { Header } from '@components/Header'
import { Footer } from '@components/Footer'
import { Button } from '@components/UI/Button'
import FeaturesList from '@components/DomoreportFeaturesList'
import DomoreportBenefits from '@components/affiliate-program/DomoreportBenefits'
import { ArrowRightIcon } from '@heroicons/react/solid'

export default function Home() {
  return (
    <>
      <NextSeo
        title="Your personal assistant for repair estimates based on your home inspection"
        description="Home inspection repair estimates within 24 hours. Get zip code specific estimates, create a repair & credits request list, and negotiate the best deal sooner."
        openGraph={{
          description: 'Home inspection repair estimates within 24 hours. Get zip code specific estimates, create a repair & credits request list, and negotiate the best deal sooner.',
          images: [
            {
              url: 'https://majordomo.com/images/offers/mockup.png',
              width: 4014,
              height: 2360,
              alt: 'Computer screen shot of Majordomo’s Domoreport'
            }
          ]
        }}
      />
      <Header />

      <main className="pt-8 px-0 container mx-auto">
        <h2 className="text-brand text-center mx-auto lg:text-5xl sm:text-4xl text-3xl font-black tracking-tight max-w-3xl uppercase px-8">
          YOUR PERSONAL ASSISTANT{' '}
        </h2>
        <h4 className="md:pb-16 pb-4 text-brand text-center mx-auto lg:text-xl sm:text-lg text-md font-bold tracking-wide sm:max-w-3xl max-w-xs uppercase px-8">
          FOR REPAIR ESTIMATES BASED ON YOUR HOME INSPECTION
        </h4>

        <div className="flex md:flex-row flex-col justify-between items-center lg:pb-36 pb-12">
          <div className="flex flex-col md:items-start items-center justify-center md:w-1/2 w-full xl:p-20 md:p-8 px-8 md:order-first order-last">
            <h1 className="font-black font-heading xl:text-7xl lg:text-6xl md:text-5xl text-2xl lg:max-w-sm max-w-sm mb-8">
              Negotiate{' '}
              <span className="xl:text-6xl lg:text-6xl md:text-5xl text-2xl">
                the best deal
              </span>
            </h1>

            <div className="w-full lg:max-w-md max-w-sm p-0 space-y-4">
              <p>
                Stack the odds on your side during seller negotiations with{' '}
                <span className="font-black">
                  ZIP Code specific repair estimates
                </span>{' '}
                based on your home inspection, prepared by industry experts.
              </p>

              <p>Order now and receive yours within 24 hours.</p>
            </div>

            <Link href="/checkout">
              <a className="mt-8">
                <Button variant="primary" clipSide="right">
                  Get your repair estimates
                </Button>
              </a>
            </Link>
          </div>

          <div className="relative sm:w-1/2 w-3/4">
            <video
              className="md:order-last order-first md:mb-0 mb-8 md:max-w-none max-w-xs mx-auto"
              width="100%"
              autoPlay
              loop
              muted
              playsInline
            >
              <source src="/videos/home-hero.mp4" type="video/mp4" />
            </video>

            <img
              src="/images/offers/mockup.png"
              className="absolute lg:inline hidden -bottom-16 2xl:-left-72 xl:-left-40 lg:-left-20"
              width="80%"
            />
          </div>
        </div>

        <div>
          <div className="lg:mb-36 mb-12 lg:pt-12 pt-0 px-8">
            <h2 className="text-5xl text-black font-black text-center mt-8 mb-4">
              How It Works
            </h2>

            <p className="font-light max-w-lg text-lg xl:mb-24 mb-12 text-center mx-auto">
              Follow a few simple steps to get access to your repair estimates,
              called a Domoreport, within 24 hours.
            </p>

            <div>
              <div className="flex xl:flex-row flex-col w-full xl:mb-24 mb-12">
                <div className="flex items-center justify-center xl:w-1/2 w-full lg:mt-0 mt-12 xl:order-first order-last">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/ILXF33MJdpc?hl=en_US&amp;rel=0&amp;autohide=1&amp;autoplay=0"
                    frameBorder="0"
                    className="rounded shadow-sm"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>

                <div className="space-y-24 xl:w-1/2 w-full lg:max-w-lg max-w-xs mx-auto xl:mb-0 mb-20 xl:order-last order-first">
                  <div className="relative lg:h-8 h-0 2xl:ml-0 ml-20">
                    <div
                      className="absolute lg:-top-4 top-0 2xl:-left-36 lg:-left-24 -left-16 h-32 bg-no-repeat bg-center"
                      style={{
                        backgroundImage:
                          'url(/images/affiliates/dashed-line.png)'
                      }}
                    >
                      <div className="bg-blue-100 flex items-center justify-center font-black text-2xl text-blue-400 font-heading lg:w-20 w-12 lg:h-20 h-12 rounded-full">
                        1
                      </div>
                    </div>
                    <h4 className="lg:text-2xl text-lg font-black max-w-sm">
                      Upload your home inspection
                    </h4>
                  </div>

                  <div className="relative lg:h-8 h-0 2xl:ml-0 ml-20">
                    <div
                      className="absolute lg:-top-4 top-0 2xl:-left-36 lg:-left-24 -left-16 h-32 bg-no-repeat bg-center"
                      style={{
                        backgroundImage:
                          'url(/images/affiliates/dashed-line.png)'
                      }}
                    >
                      <div className="bg-blue-100 flex items-center justify-center font-black text-2xl text-blue-400 font-heading lg:w-20 w-12 lg:h-20 h-12 rounded-full">
                        2
                      </div>
                    </div>
                    <h4 className="lg:text-2xl text-lg font-black max-w-sm">
                      Within 24 hours access your repair estimates in the
                      Domoreport
                    </h4>
                  </div>

                  <div className="relative lg:h-8 h-0 2xl:ml-0 ml-20">
                    <div
                      className="absolute lg:-top-4 top-0 2xl:-left-36 lg:-left-24 -left-16 h-32 bg-no-repeat bg-center"
                      style={{
                        backgroundImage:
                          'url(/images/affiliates/dashed-line.png)'
                      }}
                    >
                      <div className="bg-blue-100 flex items-center justify-center font-black text-2xl text-blue-400 font-heading lg:w-20 w-12 lg:h-20 h-12 rounded-full">
                        3
                      </div>
                    </div>
                    <h4 className="lg:text-2xl text-lg font-black max-w-sm">
                      Review with your agent
                    </h4>
                  </div>

                  <div className="relative lg:h-8 h-0 2xl:ml-0 ml-20">
                    <div className="absolute lg:-top-4 top-0 2xl:-left-36 lg:-left-24 -left-16 h-32">
                      <div className="bg-blue-100 flex items-center justify-center font-black text-2xl text-blue-400 font-heading lg:w-20 w-12 lg:h-20 h-12 rounded-full">
                        4
                      </div>
                    </div>
                    <h4 className="lg:text-2xl text-lg font-black max-w-sm">
                      Create a request list of repairs and credits for
                      negotiations
                    </h4>
                  </div>
                </div>
              </div>

              <p className="max-w-lg font-light text-center leading-8 mb-12 mx-auto font-heading">
                You can access your Domoreport securely anytime on desktop or
                mobile, and share access to collaborate with your family or your
                real estate agent.
              </p>

              <div className="flex justify-center max-w-sm mx-auto">
                <Link href={'/checkout'}>
                  <a>
                    <Button clipSide="right">Get your repair estimates</Button>
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-blue-50 flex flex-col items-center justify-center font-heading lg:mb-24 mb-12 py-12">
          <h2 className="font-black text-5xl mb-12 text-center">
            How much it costs
          </h2>

          <div className="relative flex flex-col items-center justify-center bg-blue-100 w-80 h-80 rounded-full mb-8">
            <p className="font-black text-9xl text-blue-400">
              <span className="text-5xl">$</span>
              99
            </p>
          </div>

          <div className="flex space-x-8 mb-12">
            <div className="flex flex-col items-center justify-center w-1/2 pr-8 border-r border-gray-400">
              <p>
                <span className="font-black text-4xl">24</span> hour
              </p>
              <p className="uppercase font-light text-sm">turnaround</p>
            </div>

            <div className="flex flex-col items-center justify-center w-1/2">
              <p className="font-black text-4xl">100%</p>
              <p className="uppercase font-light text-sm">GUARANTEED</p>
              <p className="uppercase font-light text-sm">SATISFACTION</p>
            </div>
          </div>

          <p className="max-w-md font-light text-center leading-8 mb-12">
            Receive your repair estimates within 24 of submitting your home
            inspection PDF. If you’re not 100% satisfied with the Domoreport,
            you can request a full refund.
          </p>

          <Link href="/checkout">
            <a>
              <Button variant="primary" clipSide="right">
                Get your repair estimates
              </Button>
            </a>
          </Link>
        </div>

        <DomoreportBenefits type="client" />

        <FeaturesList
          title="All Included Features"
          url="/checkout"
          buttonText="Get your repair estimates"
        />

        <div className="lg:mb-36 mb-12">
          <h4 className="text-center md:text-2xl text-lg font-black tracking-tight md:px-0 px-8 lg:pb-16 pb-8">
            Majordomo helps each party during a transaction
          </h4>

          <div className="md:space-y-16 space-y-8 px-8">
            <div className="flex md:flex-row flex-col space-evenly">
              <div className="flex lg:flex-row flex-col items-center md:w-1/2">
                <img
                  className="object-contain lg:w-auto md:w-1/2 w-auto folded-r-3"
                  alt="people hugging"
                  src="/images/product-pages/buyers-S.png"
                />
                <div className="md:px-8 px-0 py-4 max-w-lg">
                  <h4 className="md:text-2xl text-lg font-black tracking-tight mb-2">
                    Home Buyers
                  </h4>
                  <p>
                    Focus on the big picture with condition and repair estimates
                    organized in one easy-to-read report.{' '}
                    <Link href="/solutions/for-home-buyers">
                      <a className="text-accentBlue hover:underline">
                        Learn More
                      </a>
                    </Link>
                  </p>
                </div>
              </div>

              <div className="flex lg:flex-row flex-col items-center md:w-1/2">
                <img
                  className="object-contain lg:w-auto md:w-1/2 w-auto folded-r-3"
                  alt="woman working in coffee shop"
                  src="/images/product-pages/re-agent-S.png"
                />
                <div className="md:px-8 px-0 py-4 max-w-lg">
                  <h4 className="md:text-2xl text-lg font-black tracking-tight mb-2">
                    Real Estate Agents
                  </h4>
                  <p>
                    Save time after the inspection, negotiate smarter with
                    sellers, and close more transactions.{' '}
                    <Link href="/solutions/for-real-estate-agents">
                      <a className="text-accentBlue hover:underline">
                        Learn More
                      </a>
                    </Link>
                  </p>
                </div>
              </div>
            </div>

            <div className="flex md:flex-row flex-col space-evenly">
              <div className="flex lg:flex-row flex-col items-center md:w-1/2">
                <img
                  className="object-contain lg:w-auto md:w-1/2 w-auto folded-r-3"
                  alt="people holding sign"
                  src="/images/product-pages/sellers-S.png"
                />
                <div className="md:px-8 px-0 py-4 max-w-lg">
                  <h4 className="md:text-2xl text-lg font-black tracking-tight mb-2">
                    Home Sellers
                  </h4>
                  <p>
                    Set your home apart by fixing the biggest issues and earn
                    the trust of prospective home buyers.{' '}
                    <Link href="/solutions/for-home-sellers">
                      <a className="text-accentBlue hover:underline">
                        Learn More
                      </a>
                    </Link>
                  </p>
                </div>
              </div>

              <div className="flex lg:flex-row flex-col items-center md:w-1/2">
                <img
                  className="object-contain lg:w-auto md:w-1/2 w-auto folded-r-3"
                  alt="man inspecting house"
                  src="/images/product-pages/inspectors-S.png"
                />
                <div className="md:px-8 px-0 py-4 max-w-lg">
                  <h4 className="md:text-2xl text-lg font-black tracking-tight mb-2">
                    Home Inspectors
                  </h4>
                  <p>
                    The Domoreport finally gives home inspectors an answer to
                    the No. 1 question they get after an inspection.{' '}
                    <Link href="/solutions/for-home-inspectors">
                      <a className="text-accentBlue hover:underline">
                        Learn More
                      </a>
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-black lg:mb-36 mb-12 py-24 px-8">
          <h1 className="text-5xl text-white font-black text-center mb-12">
            Frequently Asked Questions
          </h1>

          <div className="flex lg:flex-row flex-col text-white mb-12">
            <div className="lg:w-1/2 w-full space-y-12">
              <div className="max-w-md mx-auto">
                <h4 className="text-2xl font-black font-heading mt-8 mb-4">
                  "Can I try the Domoreport before I purchase one?"
                </h4>
                <p className="font-light tracking-wider">
                  Yes. We created a couple of sample reports so you can see
                  exactly what you're ordering. You can check out a{' '}
                  <a
                    href="/demo"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gold-400 hover:underline"
                  >
                    sample report
                  </a>
                </p>
              </div>

              <div className="max-w-md mx-auto">
                <h4 className="text-2xl font-black font-heading mt-8 mb-4">
                  "What are my payment options?"
                </h4>
                <p className="font-light tracking-wider">
                  We use Stripe to securely process transactions. So we accept
                  all major credit cards to pay for the Domoreport
                </p>
              </div>

              <div className="max-w-md mx-auto">
                <h4 className="text-2xl font-black font-heading mt-8 mb-4">
                  “When will I know my Domoreport is ready?”
                </h4>
                <p className="font-light tracking-wider">
                  We know your purchase is time sensitive so we’ll send you an
                  email and text message when it’s ready so you can look at it
                  right away.
                </p>
              </div>
            </div>

            <div className="lg:w-1/2 w-full space-y-12">
              <div className="max-w-md mx-auto">
                <h4 className="text-2xl font-black font-heading mt-8 mb-4">
                  “Can I share my Domoreport?”
                </h4>
                <p className="font-light tracking-wider">
                  Absolutely. You can share by adding people during the ordering
                  process and by clicking the share button at the top of the
                  Domoreport.{' '}
                  <a
                    href="https://intercom.help/majordomo/en/articles/3216497-how-do-i-share-my-domoreport"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gold-400 hover:underline"
                  >
                    Learn more
                  </a>
                  . We also make it easy to{' '}
                  <a
                    href="https://intercom.help/majordomo/en/articles/3301636-how-do-i-read-and-use-the-request-list"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gold-400 hover:underline"
                  >
                    print and share
                  </a>{' '}
                  the request list.
                </p>
              </div>

              <div className="max-w-md mx-auto">
                <h4 className="text-2xl font-black font-heading mt-8 mb-4">
                  "Do you create Domoreports for multi-family properties?"
                </h4>
                <p className="font-light tracking-wider">
                  Yes, but our pricing and timing are different based on the
                  number of units. If you have a multi-family inspection,{' '}
                  <Link href="/contact-us">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gold-400 hover:underline"
                    >
                      contact us
                    </a>
                  </Link>{' '}
                  and we'll discuss the cost and timing for your particular
                  needs.
                </p>
              </div>

              <div className="max-w-md mx-auto">
                <h4 className="text-2xl font-black font-heading mt-8 mb-4">
                  "I'm not very technical. Will I still be able to use the
                  Domoreport?"
                </h4>
                <p className="font-light tracking-wider">
                  There's a comprehensive library of{' '}
                  <a
                    href="https://intercom.help/majordomo/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gold-400 hover:underline"
                  >
                    frequently asked questions
                  </a>{' '}
                  that will guide you through the Domoreport. There's also a{' '}
                  <a
                    href="https://youtu.be/2zEgrYvo5jE"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gold-400 hover:underline"
                  >
                    video tour
                  </a>{' '}
                  showing how everything works. And if you get stuck at any
                  point, our team will be happy to assist you at any time.
                </p>
              </div>
            </div>
          </div>

          <div className="mx-auto">
            <p className="font-light text-center text-white leading-8 tracking-wider">
              More questions? Let us help.
              <br />
              <Link href="/contact-us">
                <a className="text-gold-400 hover:underline">Contact us</a>
              </Link>{' '}
              or check out our full{' '}
              <a
                href="https://intercom.help/majordomo/en/"
                className="text-gold-400 hover:underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                FAQ
              </a>
              .
            </p>
          </div>
        </div>

        <div className="flex items-center justify-center w-full lg:mb-36 mb-12">
          <Link href="/checkout">
            <a>
              <Button variant="primary" clipSide="right">
                Get repair estimates{' '}
                <ArrowRightIcon width={18} height={18} className="ml-2" />
              </Button>
            </a>
          </Link>
        </div>
      </main>

      <Footer />
    </>
  )
}
